import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import { generateRandomString } from "../../components/DateFunction";
import { TextBox } from "../../components/components/Inputs/index";
import { data_selector } from "../../store/DataReducer";
import { GET_ROLES } from "./Api";

const UserForm = ({ handleChange, values, setLoading }) => {
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   const object = [];

  //   for (const property in rolesCheck) {
  //     rolesCheck[property] && object.push(property);
  //   }
  //   handleChange({
  //     name: "Roles",
  //     value: object,
  //   });
  // }, [rolesCheck]);
  // const HandleChangeRolesValue = useCallback(({ name, value }) => {
  //   setRolesCheck((prev) => ({ ...prev, [name]: value }));
  // }, []);
  useEffect(() => {
    console.log(values);
  }, [values]);
  return (
    <>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <TextBox
          label={t("Category En")}
          value={values["CategoryNameEn"]}
          name="CategoryNameEn"
          handleChange={handleChange}
          requiredInput
        />
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <TextBox
          label={t("Category")}
          value={values["CategoryName"]}
          name="CategoryName"
          handleChange={handleChange}
          requiredInput
        />
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <TextBox
          label={t("Color")}
          value={values["Color"]}
          name="Color"
          handleChange={handleChange}
          requiredInput
        />
      </div>
    </>
  );
};

export default UserForm;
