import { useMemo, useRef } from "react";

import CrudComponent from "../../components/CrudComponent/CrudComponent";
import {
  DELETE_CATEGORIES,
  EDIT_CATEGORIES,
  GET_CATEGORIES,
  GET_CATEGORIES_BY_ID,
} from "./Api";
import UserForm from "./UserForm";

const Categories = () => {
  const defaultData = useRef({
    Id: 0,
    CategoryName: "",
    CategoryNameEn: "",
  });

  const columnAttributes = useMemo(() => {
    return [
      {
        caption: "الاسم",
        field: "CategoryName",
        captionEn: "Name",
      },
      {
        caption: "En الاسم",
        field: "CategoryNameEn",
        captionEn: "Name En",
      },
    ];
  }, []);
  return (
    <CrudComponent
      columnAttributes={columnAttributes}
      defaultData={defaultData}
      DELETE={DELETE_CATEGORIES}
      EDIT={EDIT_CATEGORIES}
      GET={GET_CATEGORIES}
      GET_BY_ID={GET_CATEGORIES_BY_ID}
      Form={UserForm}
    />
  );
};

export default Categories;
