import { useMemo, useRef } from "react";

import CrudComponent from "../../components/CrudComponent/CrudComponent";
import {
  DELETE_QUESTION,
  EDIT_QUESTION,
  GET_QUESTION,
  GET_QUESTION_BY_ID,
} from "./Api";
import UserForm from "./UserForm";

const Categories = () => {
  const defaultData = useRef({
    Id: 0,
    QuestionText: "",
    QuestionTextEn: "",
  });

  const columnAttributes = useMemo(() => {
    return [
      {
        caption: "الاسم",
        field: "QuestionText",
        captionEn: "Name",
      },
      {
        caption: "En الاسم",
        field: "QuestionTextEn",
        captionEn: "Name En",
      },
    ];
  }, []);
  return (
    <CrudComponent
      columnAttributes={columnAttributes}
      defaultData={defaultData}
      DELETE={DELETE_QUESTION}
      EDIT={EDIT_QUESTION}
      GET={GET_QUESTION}
      GET_BY_ID={GET_QUESTION_BY_ID}
      Form={UserForm}
    />
  );
};

export default Categories;
