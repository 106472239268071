import Categories from "../../views/Categories";
import Questions from "../../views/Questions";
import Users from "../../views/Users/Users";

export const routes = [
  // {
  //   name: "questions",
  //   route: "/questions",
  //   key: "Questions",
  //   Component: Questions,
  // },
  {
    name: "Questions",
    route: "/Questions",
    key: "Admin",
    Component: Questions,
  },
  {
    name: "Categories",
    route: "/Categories",
    key: "Admin",
    Component: Categories,
  },
  {
    name: "Users",
    route: "/users",
    key: "Admin",
    Component: Users,
  },
];
