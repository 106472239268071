import REQUEST from "../../services/Request";

export const GET_CATEGORIES = async () => {
  return await REQUEST({
    method: "GET",
    url: "Category",
  });
};
export const GET_CATEGORIES_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Category/" + id,
  });
};

export const EDIT_CATEGORIES = async (data) => {
  return await REQUEST({
    method: data.Id ? "PUT" : "POST",
    url: "Category",
    data,
  });
};

export const DELETE_CATEGORIES = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Category/" + id,
  });
};
