import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectBox, TextBox } from "../../components/components/Inputs/index";
import { GET_CATEGORIES } from "../Categories/Api";

const UserForm = ({ handleChange, values, setLoading }) => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    GET_CATEGORIES().then((res) => setCategories(res));
  }, [values]);
  return (
    <>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <TextBox
          label={t("Question En")}
          value={values["QuestionTextEn"]}
          name="QuestionTextEn"
          handleChange={handleChange}
          requiredInput
        />
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <TextBox
          label={t("Question")}
          value={values["QuestionText"]}
          name="QuestionText"
          handleChange={handleChange}
          requiredInput
        />
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <SelectBox
          label={t("Category")}
          value={values["CategoryId"]}
          name="CategoryId"
          keys={{
            id: "Id",
            name: i18n.language == "en" ? "CategoryNameEn" : "CategoryName",
          }}
          dataSource={categories}
          handleChange={handleChange}
          requiredInput
        />
      </div>
    </>
  );
};

export default UserForm;
