import REQUEST from "../../services/Request";

export const GET_QUESTION_BY_USER = async (tye) => {
  return await REQUEST({
    method: "GET",
    url: "Question/view/" + tye,
  });
};

export const EDIT_QUESTION = async (data) => {
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Question",
    data: data,
  });
};

export const DELETE_QUESTION = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Question/" + id,
  });
};
export const GET_QUESTION = async () => {
  return await REQUEST({
    method: "GET",
    url: "Question",
  });
};
export const GET_QUESTION_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Question/" + id,
  });
};
